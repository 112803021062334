import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';
import image from '../../../images/christian_sec3.png';

const Container = styled.div`
  height: 100vh;
  background-color: #ba503e;
  width: calc(100% + 144px);
  margin-left: -144px;

  .image-container {
    max-width: min-content;
    margin: auto;
    margin-top: 5vh;

    img {
      max-width: 34vw;
      max-height: 86vh;
    }
    .caption {
      font-family: 'Lato';
      font-size: 20px;
      line-height: 24px;
      color: white;
      margin-left: 22px;
    }
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #aa4d3b;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

   img {
    max-width: 90%;
    max-height: 90%;
    margin: auto;
  }

  .caption {
    font-family: 'Lato';
    font-size: 3vw;
    margin: auto;
  }
`;

const ChristianSectionThree = () => {
  const { width } = useViewport();
  const breakpoint = 768;
  return (
    width <= breakpoint ? (
      <MobileContainer>
        <img src={image} />
        <div className="caption">Armstrong holds press conference with Soviet <br /> dissident Mikhail Makarenko, 1982</div>
      </MobileContainer>
    ) : (
        <Container>
          <div className="image-container">
            <img src={image} />
            <div className="caption">
              Armstrong was a popular speaker at church gatherings across America in the 1990s
      </div>
          </div>
        </Container>
      )
  )
}

export default ChristianSectionThree;