import React from 'react';
import Layout from '../../components/layout';
import { Helmet } from 'react-helmet';

import ChristianSectionOne from '../../components/bio/GradiantImage';
import SectionOneBackground from '../../images/christian_sec1.png';
import SectionOneMobileBackground from '../../images/mobile/Christian_Activist.png'

import ChristianSectionTwo from '../../components/bio/christian/ChristianSectionTwo';
import ChristianSectionThree from '../../components/bio/christian/ChristianSectionThree';

import ChristianSectionFour from '../../components/bio/BottomSectionContainer';
import SectionFourImage from '../../images/christian_sec4.png';


const Christian = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Armstrong's life and legacy: Christian</title>
        <script>
        {`
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        `}
      </script>
      </Helmet>
      <Layout stacked={true}>
        <ChristianSectionOne
          background={SectionOneBackground}
          title={'Christian Activist'}
          text={'Bill Armstrong was a vital leader in the national evangelical movement, and one of the most highly sought speakers at prayer breakfasts and other Christian events across the nation. He did not believe in cloaking his political views in the mantle of Christianity (which he thought sacrilegious). But with a good mood and charming wit, and with the self-discipline to avoid seeming judgmental or self-righteous, Armstrong pursued the life of a Christian statesman, involved in the most important issues of the day, and with an approach that was centered on his Christian principles. He believed that made his life, and his political work, more interesting, and more effective. He routinely opened meetings with prayers, frequently discussed the importance of religion in politics, and was ready to offer his Christian testimony at almost any invitation.'}
          mobileBackground={SectionOneMobileBackground}
        />
        <ChristianSectionTwo />
        <ChristianSectionThree />
        <ChristianSectionFour
          image={SectionFourImage}
          blackCaption={'Transforming the Campus of CCU in Lakewood, '}
          orangeCaption={'Colorado'}
          blackLink={'Click to page: '}
          orangeLink={'Educator'}
          route="/bio/educator"
        />
      </Layout>
    </>
  )
}

export default Christian;