import React from 'react';
import styled from 'styled-components';
import { useViewport } from '../../../hooks/useViewport';

import image from '../../../images/christian_sec2.png';
import MobileContainer from '../ImageLeftWhiteBGContainer';

const Container = styled.div`
  display: grid;
  grid-template-columns: 39.1vw 28.8vw 32.1vw;
  grid-template-rows: 100%;
  height: 100vh;
  margin-left: -72px;

  .left {
    grid-column: 1;
    margin-top: 12.8vh;
    padding-left: 106px;

    img {
      max-width: 31.7vw;
    }

    .caption {
      font-family: 'Lato';
      font-size: 20px;
      line-height: 24px;
    }
  }

  .middle {
    grid-column: 2;
    font-family: 'Lato';
    font-size: 1.2vw;
    color: #212028;
    margin-top: 21.4vh;
    max-width: 20.7vw;
    justify-self: center;
  }

  .right {
    grid-column: 3;
    font-family: 'Lato';
    font-size: 1.2vw;
    max-width: 20.7vw;
    margin-top: 21.4vh;
  }
`;

const ChristianSectionTwo = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return (
    width <= breakpoint ? (
      <MobileContainer
        captionBlack={"Armstrong in a TV ad filmed for the “Colorado Tax Force on Religious Freedom"}
        captionOrange={'1996'}
        text={`He was the lead sponsor of legislation making 1983 the “Year of the Bible,” and declaring a national day of prayer in 1988, which became an annual event. He helped organize the “Washington for Jesus” rally in 1980, hosted Bible study meetings in both the House and Senate,
    and served as keynote speaker at the 1988 National Prayer Breakfast, where Billy Graham later called him “the most inspirational and enlightening speaker the Breakfast has ever had.” He served for many years on the board of Campus Crusade for Christ (now called Cru), as well as Trinity Forum, and the Christian Businessman’s Committee. He was an active supporter of Prison Fellowship and many other national Christian groups. He believed civic involvement is the duty of Christians, and taught that the responsibilities of American citizens, and of committed Christians, are inextricably connected.`}
        image={image}
      />
    ) : (
        <Container>
          <div className="left">
            <img src={image} />
            <div className="caption">Armstrong in a TV ad filmed for the “Colorado Tax Force on Religious Freedom", <span style={{ color: '#C84836' }}>1996</span>
            </div>
          </div >
          <div className="middle">
            {`He was the lead sponsor of legislation making 1983 the “Year of the Bible,” and declaring a national day of prayer in 1988, which became an annual event. He helped organize the “Washington for Jesus” rally in 1980, hosted Bible study meetings in both the House and Senate,
            and served as keynote speaker at the 1988 National Prayer Breakfast, where Billy Graham later called him “the most inspirational and enlightening speaker the Breakfast has ever had.” He served for many years on the board of Campus Crusade for Christ (now called Cru), as well as Trinity Forum, and the Christian Businessman’s Committee. He was an active supporter of Prison Fellowship and many other national`}
          </div>
          <div className="right">
            {'Christian groups. He believed civic involvement is the duty of Christians, and taught that the responsibilities of American citizens, and of committed Christians, are inextricably connected.'}
          </div>
        </Container >

      )
  )
};

export default ChristianSectionTwo;